<div class="w-full max-w-2xl bg-gray-700 rounded-lg shadow-lg p-5">
  <!-- Service Information Section -->
  <section class="bg-gray-800 bg-opacity-50 p-6 rounded-lg">
    <h2 class="text-center text-2xl font-extrabold text-yellow-400 mb-3">{{ selectedProduct.name }}</h2>
    <p class="text-center text-base text-gray-300 mb-6 leading-relaxed">{{ selectedProduct.desc }}</p>
    
 
      <!-- Image Display Section -->
  <figure class="relative flex justify-center items-center my-5">
    <img [src]="selectedProduct.srcPreview" alt="{{ selectedProduct.name }}" class="w-64 h-64 rounded-lg shadow-lg animate-fade-in cursor-pointer">
   
    <div class="absolute top-2 right-2 flex flex-col gap-1">
      <ng-container *ngFor="let material of materials">
        <div class="bg-black bg-opacity-70 text-yellow-400 px-2 py-1 rounded text-xs">
          {{ material }} 
        </div>
      </ng-container>
    
    </div>
  </figure>
  <figcaption class="bg-black bg-opacity-70 text-yellow-400 font-bold text-lg px-3 py-1 rounded-lg" style="text-align: justify;">
    <s class="text-gray-400 text-sm">{{ selectedProduct.status }}</s>
    {{ selectedProduct.action }}
  </figcaption>

  </section>



  <!-- Мініатюри -->
  <div id="thumbnails" class="flex gap-3 overflow-x-auto p-2">
    <div *ngFor="let product of products; let i = index; trackBy: trackByProduct"
         class="relative flex-shrink-0 w-24 h-24 cursor-pointer group"
         [class.selected-thumbnail]="i === selectedProductIndex"
         (click)="selectProduct(i)">
      <div class="absolute top-2 left-2 bg-black bg-opacity-70 text-yellow-400 px-2 py-1 rounded text-xs">
        {{ product.cost }}
      </div>
     
      <img [src]="product.srcPreview" alt="{{ product.name }}" class="w-full h-full rounded-lg shadow-lg group-hover:scale-110 group-hover:shadow-xl transition-transform">
      <div *ngIf="i === selectedProductIndex" class="absolute bottom-1 left-2 bg-black bg-opacity-70 text-yellow-400 text-xs font-bold rounded px-2 py-1">
        Вибрано
      </div>
    </div>
  </div>

  <!-- Форма -->
  <form class="mt-5" (ngSubmit)="handleSubmit()">
    <div class="mb-4">
      <label for="phone" class=" text-white block text-sm font-bold mb-2">Ваш номер телефону</label>
      <input type="tel" id="phone" [(ngModel)]="form.phone"   placeholder="Введіть номер" required
             class="w-full p-3 bg-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400">
    </div>
    <div class="mb-4">
      <label for="comment" class=" text-white block text-sm font-bold mb-2">Коментар</label>
      <textarea id="comment" [(ngModel)]="form.comment"  rows="3" placeholder="Ваші побажання"
                class="w-full p-3 bg-gray-600 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400"></textarea>
    </div>
    <div class="text-white block text-[12px] font-bold m-2">
     Для малюнку подрібено фото та опис в строках оріентуюсь на Вас!
    </div>
    <button type="submit" id="submit-btn"
            class="w-full bg-yellow-400 text-gray-800 font-bold py-3 rounded-lg hover:bg-yellow-300 transition-colors">
      Надіслати
    </button>
    <div *ngIf="isFormSubmitted" class="text-center text-yellow-400 font-bold text-lg mt-3">
      Я зв'яжусь з вами найближчим часом...
    </div>
  </form>
</div>
