<p-overlayPanel #opShowText>

  <div *ngIf="selectCardImage && selectCardImage.text" class="w-full max-w-sm">
    <div class="flex items-center border-b border-teal-500 py-2">
      <img *ngIf="selectCardImage.srcPreview"
           class="-mt-8 inline-flex rounded-full"
           [src]="selectCardImage.srcPreview"
           width="64"
           height="64"
           alt="User"
      />
      <p class="appearance-none bg-transparent border-none w-full text-gray-100 mr-3 py-1 px-2 leading-tight focus:outline-none"
      >{{ selectCardImage.text }}
      </p>

    </div>

  </div>


</p-overlayPanel>
<p-overlayPanel #opOrderByImage>
  <app-order-by-image-form *ngIf="showOrderByImageForm "
                           [typeOrder]="'Хочу подібний'"
                           [selectImageModel]="selectCardImage"
                           [selectModel]="selectCard"
                           (onClose)="showOrderByImageForm = false"
  ></app-order-by-image-form>
</p-overlayPanel>

<div class="ml-[60px] mt-2">
  <div
    [class]="'bg-color-custom'"
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class=" bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
    <div class="m-3 p-3">
      <div class="w-full  p-3 rounded-lg shadow-lg">
        <div class="flex justify-center">
          <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1">
            <ng-container *ngFor="let  itemImageCard  of cards; let first = first">
              <div
                class=" rounded-lg shadow-lg  border border-zinc-700 text-center aspect-square relative  inline-block transition-transform transform hover:scale-105">
                <img *ngIf="!itemImageCard.showZoom"
                     [src]="itemImageCard.srcPreview" alt="upload_image"
                     class=" "
                >
                <!--<button *ngIf="itemImageCard.text"
                        #targetShowText
                        (click)="selectCardImage = itemImageCard;  opShowText.toggle($event,targetShowText)"
                        class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 left-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                >
                  Опис
                </button>-->
                <app-zoom-image *ngIf="itemImageCard.showZoom"
                                [srcImage]="itemImageCard.srcPreview">
                </app-zoom-image>

                <button *ngIf="!itemImageCard.showZoom"
                        (click)="itemImageCard.showZoom = !itemImageCard.showZoom; selectCardImage = itemImageCard;"
                        class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 left-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                >
                  <i [class]="zoomPlusIcon"></i>
                </button>
                <button *ngIf="itemImageCard.showZoom"
                        (click)="itemImageCard.showZoom = !itemImageCard.showZoom; selectCardImage = itemImageCard;"
                        class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 left-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                >
                  <i [class]="zoomMinusIcon"></i>
                </button>


                <div class="flex flex-row gap-1 w-[150px] ">

                  <button #targetOrderByImage
                          class=" absolute bottom-3 right-0 w-full bg-gradient-to-r from-purple-500/65 to-indigo-500/65 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-1 px-1 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105  fade-out"
                          (click)="selectCardImage = itemImageCard; showOrderByImageForm =true; opOrderByImage.toggle($event,targetOrderByImage)"
                  >
                    Хочу подібний!
                    <i class="fa-solid fa-circle-chevron-right"></i>
                  </button>

                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-1 mb-1">
    </div>
  </div>
</div>

