import {
  Component,
  OnInit,
  Input,
} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import { FormsModule, NgForm } from '@angular/forms';
import { CdServices } from 'src/app/services/common/cd.services';
import { ServerOrderServices } from 'src/app/services/servers/server-order.services';
import { BasketServices } from 'src/app/services/common/basket.services';
import { getDiscount } from 'src/app/traits/string-tools';

@Component({
  selector: 'app-header-form',
  templateUrl: './header-form.component.html',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    FormsModule
  ],

})
export class HeaderFormComponent implements OnInit {
@Input()  products: any[]
public form: any = {
  phone: '',
  comment: '',
};
  selectedProductIndex: number = 0;
  isFormSubmitted: boolean = false;

  constructor(public cd:CdServices,public serverOrder: ServerOrderServices, public basketServices: BasketServices,) {
  }

  ngOnInit(): void {
    // Инициализация может быть полезна для дополнительных данных, но здесь не требуется
  }

  selectProduct(index: number): void {
    this.selectedProductIndex = index;
  }

  handleSubmit(): void {
    this.isFormSubmitted = true;
    setTimeout(() => {
      this.isFormSubmitted = false;
    }, 3000);
    this.submit();
  }
  submit() {
    let orderCard: any = {
      url: window.location.href,
      type: 'Продукт',
      name: 'Назва послуги - '+ this.selectedProduct.name,
      desc:'Опис послуги - ' + this.selectedProduct.desc,
      srcPreview:  this.selectedProduct.srcPreview,
      cost: 'Повна вартість - '+this.selectedProduct.cost,
      discount: 'Зі знижкою - '+  this.selectedProduct.action ? this.selectedProduct.action : getDiscount(this.selectedProduct.cost)+' грн',
      action:this.cd.showActionsSales?'АКЦИЯ-'+(this.selectedProduct.action ? 'СУПЕР ЦІНА - '+this.selectedProduct.action:'Знижка 30%('+getDiscount(this.selectedProduct.cost)+' грн )'):'НЕМА (АКЦИИ)',
      comment:'Коментар та побажання - ' + this.form.comment,
      phone:this.form.phone,
    }
    this.serverOrder.sendOrder(orderCard, (responseTelegramBot: any) => {
      this.basketServices.data = {
        ...this.form, ...this.selectedProduct
      }
      this.basketServices.save()
    })
  }
  get selectedProduct(): any {
    return this.products[this.selectedProductIndex];
  }

  trackByProduct(index: number, item: any): number {
    return index; // Используется для улучшения производительности при рендеринге списка
  }
get  materials() {
    return  this.selectedProduct.type.split('_').filter((word) => word.trim());
    }
}
